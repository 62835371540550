<template>
  <div class="devices">
    <div class="new-campaign new-device">
      <div class="container">
        <div v-show="device.status == 'linked'">
          <div class="header-wrap">
            <h2>{{ $t("devices.title") }}</h2>
          </div>

          <div class="creation-wrap">
            <ul class="cf">
              <li><span>1</span></li>
              <li class="active"><span class="yellow">2</span>{{ $t("devices.location") }}</li>
              <li><span>3</span></li>
            </ul>

            <div class="creation-block">
              <h3>{{ $t("devices.locationTitle") }}</h3>
              <p>{{ $t("devices.locationDescription") }}</p>
              <div class="map-wrap">
                <div class="form-wrap">
                  <label for="geocoder">{{ $t("devices.adressSearch") }}</label>
                  <div id="geocoder" class="geocoder"></div>
                </div>
                <div id="map" style="width: 100%; height: 400px"></div>
              </div>
            </div>

            <a @click="add_location" class="button button-ci button-100">{{ $t("devices.toStep") }} 3 ›</a>
          </div>
        </div>

        <div v-show="device.status == 'located'">
          <div class="header-wrap">
            <h2>{{ $t("devices.title") }}</h2>
          </div>

          <div class="creation-wrap">
            <ul class="cf">
              <li><span>1</span></li>
              <li><span>2</span></li>
              <li class="active"><span class="yellow">3</span>{{ $t("devices.verification") }}</li>
            </ul>

            <div class="creation-block">
              <h3>{{ $t("devices.verificationTitle") }}</h3>
              <p>{{ $t("devices.verificationDescription") }}</p>
              <div class="form-wrap upload-wrap file-upload">
                <label class="button button-gray" for="image-upload"
                  >{{ $t("devices.chooseImage") }} <span class="mobile-only" style="display: none">{{ $t("devices.takeFoto") }}</span></label
                >
                <input id="image-upload" type="file" name="photo" style="display: none" @change="verificationImage" />
                <span id="file-name" class="file-box"></span>
              </div>
            </div>

            <a @click="add_verification_image" class="button button-ci button-100">{{ $t("devices.verificationButton") }}</a>
          </div>
        </div>

        <div v-show="device.status == 'running' || device.status == 'deleted'">
          <div class="header-wrap cf">
            <div class="header-left">
              <h2>{{ device.name }}</h2>
            </div>
          </div>

          <devices-nav></devices-nav>

          <div class="row row-gutter-20">
            <div class="col-16">
              <div v-if="chart_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <div v-if="impressions" class="content-wrap">
                  <header class="content-header">
                    <h3>
                      {{ $t("devices.stats") }}
                      <span class="tooltip">
                        <span class="material-icons" style="color: #969696">info</span>
                        <md-tooltip :md-direction="direction">{{ $t("devices.statsHelp") }}</md-tooltip>
                      </span>
                    </h3>
                  </header>
                  <div class="content-body">
                    <div ref="chart" style="width: 100%; height: 300px"></div>
                  </div>
                </div>
              </div>

              <div class="content-wrap ad-pipe">
                <header class="content-header">
                  <h3>
                    {{ $t("devices.ads") }}
                    <span class="tooltip">
                      <span class="material-icons" style="color: #969696">info</span>
                      <md-tooltip :md-direction="direction">{{ $t("devices.adsHelp") }}</md-tooltip>
                    </span>
                  </h3>
                </header>
                <div class="content-body">
                  <div v-if="toggled_ad_pipe" class="ad-pipe-resp">
                    <div v-if="ads.length == 0">
                      <p style="text-align: center">{{ $t("devices.adsEmpty") }}</p>
                    </div>
                    <div v-else>
                      <div v-for="ad in ads" v-bind:key="ad.uuid">
                        <div :class="'device-ad-wrap cf status-' + ad.status">
                          <a
                            class="report-ad"
                            :href="
                              'mailto:info@oohlemon.com?subject=Anzeige%20gemeldet&body=Anzeige%20ID%3A%20' +
                              ad.uuid +
                              '%0D%0AGrund%3A%20(Bitte%20ausf%C3%BCllen)'
                            "
                            ><span class="material-icons">report</span><span>{{ $t("devices.adsReport") }}</span></a
                          >
                          <div class="device-ad-img">
                            <p><img :src="ad.asset_file" /></p>
                          </div>
                          <div class="device-ad-body">
                            <h3>
                              {{ ad.name }}
                              <span v-if="ad.ad_type == 'advertisment'" style="font-size: 14px; font-weight: 400; font-style: italic"
                                >({{ $t("global.adInternal") }})</span
                              >
                              <span v-if="ad.ad_type == 'campaign'" style="font-size: 14px; font-weight: 400; font-style: italic"
                                >({{ $t("global.adExternal") }})</span
                              >
                            </h3>
                            <p>
                              {{ $t("devices.adsStatus") }}
                              <span v-if="ad.status == 'created'">{{ $t("devices.adRequest") }}</span>
                              <span v-if="ad.status == 'delivered'">{{ $t("devices.adServed") }}</span>
                              <i v-if="ad.status == 'delivered'">
                                {{ $t("devices.and") }}
                                <span>{{ ad.device_count }} {{ $t("devices.people") }}</span>
                                {{ $t("devices.watched") }}</i
                              >.
                            </p>
                            <p>{{ $t("devices.adRequest") }}: {{ ad.timestamp_fetched }} {{ $t("devices.time") }}</p>
                            <p v-if="ad.status == 'delivered'">{{ $t("devices.adServed") }}: {{ ad.timestamp_delivered }} {{ $t("devices.time") }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="ad-pipe-resp-info">
                    <div v-if="resp_mode">
                      <a @click="toggled_ad_pipe = true" class="button button-ci button-100 button-small button-round">{{ $t("devices.adsOpen") }}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-wrap">
                <header class="content-header header-with-toggle">
                  <h3>
                    <span class="hoverable" @click="toggleContent('#branchen-content', $event)">{{ $t("devices.industires") }}</span>
                    <span class="tooltip">
                      <span class="material-icons" style="color: #969696">info</span>
                      <md-tooltip :md-direction="direction">{{ $t("devices.industriesHelp") }}</md-tooltip>
                    </span>
                  </h3>
                  <div class="dropdown-container" @click="toggleContent('#branchen-content', $event)">
                    <span class="dropdown-arrow">
                      <img src="../../assets/icons/dropdown.svg" />
                    </span>
                  </div>
                </header>
                <div class="content-body hide-toggle" id="branchen-content">
                  <div class="creation-block">
                    <form @submit="update_industries" class="form" method="post">
                      <div class="tags-wrap cf">
                        <div class="row row-gutter-20">
                          <div v-for="industry in industries" v-bind:key="industry.id" class="col-12">
                            <div class="main-industries">
                              <div class="label custom-checkbox-conainer">
                                <label :for="'checkbox-' + industry.id">{{ industry.name }}</label>
                                <input
                                  type="checkbox"
                                  :id="'checkbox-' + industry.id"
                                  :value="industry.id"
                                  v-model="device.industries"
                                  @change="mainIndustryCheckbox(industry.id)"
                                />
                                <span class="dropdown-arrow" @click="toggleDropdown(industry.id, $event)">
                                  <img src="../../assets/icons/dropdown.svg" />
                                </span>
                              </div>
                            </div>

                            <div class="sub-industries" :id="'dropdown-' + industry.id">
                              <div v-for="subIndustry in industry.industries" v-bind:key="subIndustry.id" class="sub-industry">
                                <div class="label custom-checkbox-conainer">
                                  <label :for="'checkbox-' + subIndustry.id">{{ subIndustry.name }}</label>
                                  <input type="checkbox" :id="'checkbox-' + subIndustry.id" :value="subIndustry.id" v-model="device.industries" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="button-toggle-wrapper">
                          <button class="button button-grey button-small" @click="activateAll($event)">{{ $t("global.chooseAll") }}</button>
                          <button class="button button-grey button-small" @click="deactivateAll($event)">{{ $t("global.chooseNone") }}</button>
                        </div>

                        <p style="margin: 20px 0 0 0">
                          <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="content-wrap">
                <header class="content-header">
                  <h3>
                    {{ $t("devices.display") }}
                    <span class="tooltip">
                      <span class="material-icons" style="color: #969696">info</span>
                      <md-tooltip :md-direction="direction">{{ $t("devices.displayHelp") }}</md-tooltip>
                    </span>
                  </h3>
                </header>
                <div class="content-body">
                  <div class="device-image" style="margin-left: -20px; margin-top: -20px; width: calc(100%+40px)">
                    <img :src="device.device_image" />
                    <div class="image-edit">
                      <div class="upload-wrap file-upload">
                        <label class="button button-gray button-small button-round" for="image-upload">{{ $t("devices.newImage") }}</label>
                        <input id="image-upload" type="file" name="photo" style="display: none" @change="verificationImage" />
                      </div>
                      <a @click="add_verification_image" class="button button-ci button-small button-round">{{ $t("global.updateButton") }}</a>
                    </div>
                    <div class="image-text"><span id="file-name-new" class="file-box"></span></div>
                  </div>

                  <p style="margin: 0 0 5px 0">
                    <strong>{{ $t("global.status") }}: </strong>
                    <span v-if="device.status == 'created'">{{ $t("global.created") }}</span>
                    <span v-if="device.status == 'linked'">{{ $t("global.linked") }}</span>
                    <span v-if="device.status == 'located'">{{ $t("global.located") }}</span>
                    <span v-if="device.status == 'running'">{{ $t("global.running") }}</span>
                    <span v-if="device.status == 'deleted'">{{ $t("global.hidden") }}</span>
                  </p>

                  <p style="margin: 0" v-if="device.schedule">
                    <span
                      ><strong>{{ $t("devices.scheduleUsed") }}:</strong> {{ device.schedule.name }}</span
                    >
                  </p>
                  <p style="margin: 0" v-else>
                    <span style="display: block; padding: 14px 20px" class="alert alert-danger"> {{ $t("devices.scheduleEmpty") }} </span>
                  </p>
                  <p style="margin: 0 0 5px 0">
                    <strong>{{ $t("global.deviceID") }}:</strong> {{ device.device_id }}
                  </p>

                  <div class="form-wrap">
                    <label for="name" style="color: black; margin-top: 10px">{{ $t("global.name") }}</label>
                    <input v-model="device.name" class="form-input" type="name" name="name" id="name" style="margin-bottom: 12px" />
                    <a @click="update_name" class="button button-ci button-small button-100 button-round">{{ $t("devices.saveName") }}</a>
                  </div>

                  <div class="form-wrap">
                    <label for="description" style="color: black; margin-top: 10px">{{ $t("global.infos") }}</label>
                    <textarea
                      v-model="device.description"
                      class="form-input"
                      type="description"
                      name="description"
                      id="description"
                      style="margin-bottom: 12px; min-height: 130px"
                      :placeholder="placeholder"
                    />
                    <a @click="update_description" class="button button-ci button-small button-100 button-round">{{ $t("devices.saveInfos") }}</a>
                  </div>
                </div>
              </div>
              <div class="content-wrap">
                <header class="content-header">
                  <h3>
                    {{ $t("devices.displaySettings") }}
                    <span class="tooltip">
                      <span class="material-icons" style="color: #969696">info</span>
                      <md-tooltip :md-direction="direction">{{ $t("devices.displaySettingsHelp") }}</md-tooltip>
                    </span>
                  </h3>
                </header>
                <div class="content-body">
                  <div class="schedule-setting" style="margin-bottom: 40px">
                    <div class="form-wrap select-wrap" style="margin-bottom: 12px">
                      <label for="schedule">{{ $t("global.schedule") }}</label>
                      <select v-model="device.schedule_uuid" class="select-wrap" id="schedule">
                        <option v-for="schedule in schedules" v-bind:key="schedule.uuid" v-bind:value="schedule.uuid">
                          {{ schedule.name }}
                        </option>
                      </select>
                    </div>
                    <a @click="update_schedule" class="button button-ci button-small button-100 button-round">{{ $t("devices.saveSchedule") }}</a>
                  </div>

                  <div class="device-orientation">
                    <form @submit="update_device_orientation" class="form" method="post">
                      <div class="form-wrap select-wrap" style="margin-bottom: 12px">
                        <label for="rotate">{{ $t("devices.orientation") }}</label>
                        <select v-model="device.rotate" class="select-wrap" id="rotate">
                          <option value="0">{{ $t("global.default") }}</option>
                          <option value="90">90 {{ $t("devices.degree") }}</option>
                          <option value="180">180 {{ $t("devices.degree") }}</option>
                          <option value="270">270 {{ $t("devices.degree") }}</option>
                          <option value="360">360 {{ $t("devices.degree") }}</option>
                        </select>
                      </div>

                      <p style="margin: 0">
                        <input type="submit" :value="$t('devices.saveOrientation')" class="button button-small button-ci button-round button-100" />
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div class="content-wrap">
                <header class="content-header">
                  <h3>
                    Fallback Settings
                  </h3>
                </header>
                <div class="content-body">
                  <div class="schedule-setting" style="margin-bottom: 40px">
                    <div class="form-wrap select-wrap" style="margin-bottom: 12px">
                      <label for="schedule">Fallback Playlist</label>
                      <select v-model="device.fallback_playlist_uuid" class="select-wrap" id="schedule">
                        <option v-for="playlist in playlists" v-bind:key="playlist.uuid" v-bind:value="playlist.uuid">
                          {{ playlist.name }}
                        </option>
                      </select>
                    </div>
                    <a @click="update_fallback_playlist" class="button button-ci button-small button-100 button-round">{{ $t("devices.saveSchedule") }}</a>
                  </div>
                  <p>
                    Fallback synced: {{ device.offline_fallback_synced }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="report-ad">
      <div class="dialog-content">
        <div class="dialog-c-title">{{ $t("devices.adsReport") }}</div>
        <div class="dialog-c-text">
          <p style="margin-top: 0">{{ $t("devices.adsReportSure") }}</p>
          <div class="form-wrap">
            <label for="reason">{{ $t("devices.reason") }}</label>
            <input v-model="report.reason" type="text" class="form-input" />
          </div>
        </div>
        <div class="dialog-c-buttons">
          <div class="row">
            <div class="col-12">
              <a @click="$modal.hide('report-ad')" class="button button-green button-small button-100">{{ $t("devices.adsReport") }}</a>
            </div>
            <div class="col-12">
              <a @click="$modal.hide('report-ad')" class="button button-grey button-small button-100">{{ $t("global.close") }}</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import DevicesNav from "../../components/DevicesNav.vue";
import { Plugins } from "@capacitor/core";
const { Geolocation } = Plugins;
import mapboxgl from "mapbox-gl";
import echarts from "echarts";
import mapbox_geo from "mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "device",
  components: { DevicesNav },
  data() {
    return {
      errors: [],
      device: {
        industries: [],
      },
      coordinates: {
        lat: "",
        lng: "",
      },
      device_image: "",
      schedules: [],
      modal: {
        schedule_uuid: "",
      },
      impressions: false,
      ads: [],
      chart_loading: true,
      echart_options: {
        color: ["#4DAA57", "#F3A712"],
        legend: {},
        tooltip: {},
        dataset: {
          source: [["date", this.$t("global.showed"), this.$t("global.contacts")]],
        },
        xAxis: {
          type: "category",
        },
        yAxis: {},
        series: [{ type: "bar" }, { type: "bar" }],
      },
      report: {
        uuid: "",
        reason: "",
      },
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      resp_mode: false,
      toggled_ad_pipe: true,
      direction: "left",
      placeholder: this.$t("devices.placeholder"),
      playlists: []
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.device = response.data.device;
          if (this.device.status == "linked") {
            this.get_coordinates();
            setTimeout(() => {
              this.show_map();
            }, 500);
          }
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid + "/statistics", { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.impressions = response.data;

          setTimeout(() => {
            var that = this;
            Object.keys(response.data).forEach(function (key) {
              that.echart_options.dataset.source.push([response.data[key].date, response.data[key].impressions, response.data[key].contacts]);
            });
            this.chart_loading = false;
            setTimeout(() => {
              this.init_chart();
            }, 500);
          }, 500);
        });

      this.get_ads(1);
      this.get_industries(1);
    },
    get_industries(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries?page=" + page, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;

          if (response.data.meta.pagination.links.next) {
            this.get_industries(response.data.meta.pagination.links.next.split("=")[1]);
          }
        });
    },
    get_ads(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid + "/ads?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.ads = response.data.ads;
        });
    },
    get_schedules() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/schedules", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.schedules = response.data.schedules;
        });
    },
    get_playlists() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/playlists", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.playlists = response.data.playlists;
        });
    },
    async get_coordinates() {
      this.coordinates.lat = 48.799427;
      this.coordinates.lng = 9.7960535;
      var coordinates = await Geolocation.getCurrentPosition();
      console.log("Current", coordinates);
      this.coordinates.lat = coordinates.coords.latitude;
      this.coordinates.lng = coordinates.coords.longitude;
    },
    show_map() {
      mapboxgl.accessToken = "pk.eyJ1IjoibmlrNzM2IiwiYSI6ImNqZjJlbXB6NzB4cW8ycXBuOHdkcTk4eGsifQ.mUYmP9tL6F1qWnYOhhVQ9g";

      var map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [this.coordinates.lng, this.coordinates.lat],
        zoom: 8,
      });

      var currentMarkers = [];

      var marker = new mapboxgl.Marker({ color: this.$themeColor }).setLngLat([this.coordinates.lng, this.coordinates.lat]).addTo(map);
      currentMarkers.push(marker);

      var geocoder = new mapbox_geo({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      });

      document.getElementById("geocoder").appendChild(geocoder.onAdd(map));

      var that = this;
      map.on("load", function () {
        geocoder.on("result", function (ev) {
          if (currentMarkers !== null) {
            for (var i = currentMarkers.length - 1; i >= 0; i--) {
              currentMarkers[i].remove();
            }
          }
          var marker = new mapboxgl.Marker({ color: that.$themeColor })
            .setLngLat([ev.result.geometry.coordinates[0], ev.result.geometry.coordinates[1]])
            .addTo(map);
          currentMarkers.push(marker);
          that.coordinates.lat = ev.result.geometry.coordinates[1];
          that.coordinates.lng = ev.result.geometry.coordinates[0];
        });
      });

      map.on("click", function (e) {
        if (currentMarkers !== null) {
          for (var i = currentMarkers.length - 1; i >= 0; i--) {
            currentMarkers[i].remove();
          }
        }

        var marker = new mapboxgl.Marker({ color: that.$themeColor }).setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map);
        currentMarkers.push(marker);

        that.coordinates.lat = e.lngLat.lat;
        that.coordinates.lng = e.lngLat.lng;
      });
    },
    add_location() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            lat: this.coordinates.lat,
            lng: this.coordinates.lng,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          //this.$router.push("/devices/" + response.data.device.uuid);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    verificationImage(event) {
      console.log(event.target.files[0]);
      this.device_image = event.target.files[0];
      document.getElementById("file-name").innerHTML = event.target.files[0].name;
      document.getElementById("file-name-new").innerHTML = event.target.files[0].name;
    },
    add_verification_image() {
      var form_data = new FormData();
      form_data.append("device_image", this.device_image);
      if (this.device_image) {
        axios
          .patch(process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid, form_data, { headers: { Authorization: store.getters.getToken } })
          .then((response) => {
            this.device = response.data.device;
            //this.$router.push("/devices/" + response.data.device.uuid);
            document.getElementById("file-name").innerHTML = null;
            document.getElementById("file-name-new").innerHTML = null;
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          text: this.$t("ads.fileError"),
        });
      }
    },
    change_schedule() {
      this.modal.schedule_uuid = "";
      this.$modal.show("change-schedule");
    },
    update_schedule() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            schedule_uuid: this.device.schedule_uuid,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update_name() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            name: this.device.name,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update_description() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            description: this.device.description,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    set_schedule_internal() {
      axios
        .post(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid + "/set_schedule_internal",
          {},
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$router.push("/devices/" + response.data.device.uuid);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    set_schedule_disabled() {
      axios
        .post(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid + "/set_schedule_disabled",
          {},
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$router.push("/devices/" + response.data.device.uuid);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    set_schedule_external() {
      axios
        .post(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid + "/set_schedule_external",
          {},
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.device = response.data.device;
          this.$router.push("/devices/" + response.data.device.uuid);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    report_ad(ad) {
      console.log(ad);
      this.report.uuid = ad;
      this.$modal.show("report-ad");
    },
    init_chart() {
      var chart = echarts.init(this.$refs.chart);
      chart.setOption(this.echart_options);
    },
    update_device_orientation: function (e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            rotate: this.device.rotate,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.$router.push("/devices");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    update_industries: function (e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            industries: this.device.industries,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        });
    },
    toggleDropdown(id, event) {
      document.querySelector("#dropdown-" + id).classList.toggle("active");
      event.target.classList.toggle("active");
    },
    mainIndustryCheckbox(id) {
      var mainChecked = document.querySelector("#checkbox-" + id).checked;
      var currentSubIndustries = this.industries.find((x) => x.id === id).industries;

      currentSubIndustries.forEach((subIndustry) => {
        var checkbox = document.querySelector("#checkbox-" + subIndustry.id);

        if (mainChecked === true) {
          checkbox.checked = true;
          if (!this.device.industries.includes(subIndustry.id.toString())) {
            this.device.industries.push(subIndustry.id.toString());
          }
        } else {
          checkbox.checked = false;
          if (this.device.industries.includes(subIndustry.id.toString())) {
            var myIndex = this.device.industries.findIndex((x) => x == parseInt(subIndustry.id));
            this.$delete(this.device.industries, myIndex);
          }
        }
      });
    },
    toggleContent(id, event) {
      document.querySelector(id).classList.toggle("hide-toggle");
      event.target.classList.toggle("active");
    },
    activateAll(e) {
      e.preventDefault();

      this.industries.forEach((everyIndustry) => {
        document.querySelector("#checkbox-" + everyIndustry.id).checked = true;

        this.device.industries.push(everyIndustry.id.toString());

        everyIndustry.industries.forEach((everyIndustry) => {
          document.querySelector("#checkbox-" + everyIndustry.id).checked = true;
          this.device.industries.push(everyIndustry.id.toString());
        });
      });
    },
    deactivateAll(e) {
      e.preventDefault();

      this.industries.forEach((everyIndustry) => {
        document.querySelector("#checkbox-" + everyIndustry.id).checked = false;

        if (this.device.industries.includes(everyIndustry.id.toString())) {
          var myIndex = this.device.industries.findIndex((x) => x == parseInt(everyIndustry.id));
          this.$delete(this.device.industries, myIndex);
        }

        everyIndustry.industries.forEach((everyIndustry) => {
          document.querySelector("#checkbox-" + everyIndustry.id).checked = false;

          if (this.device.industries.includes(everyIndustry.id.toString())) {
            var myIndex = this.device.industries.findIndex((x) => x == parseInt(everyIndustry.id));
            this.$delete(this.device.industries, myIndex);
          }
        });
      });
    },
    update_fallback_playlist() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/devices/" + this.$route.params.uuid,
          {
            fallback_playlist_uuid: this.device.fallback_playlist_uuid,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        });
    }
  },
  mounted() {
    this.get_data();
    this.get_schedules();
    this.get_playlists();
    if (window.innerWidth < 500) {
      this.resp_mode = true;
      this.toggled_ad_pipe = false;
      this.direction = "bottom";
    } else {
      this.resp_mode = false;
      this.direction = "left";
    }
  },
};
</script>

<style lang="scss">
.device-switch {
  margin-top: 10px;

  a {
    display: inline-block;
    width: 49%;
    text-align: center;
    background: var(--ci-color);
    color: var(--ci-contrast-color);
    padding: 5px 0;
    border-radius: 4px;
    margin-right: 2%;
  }

  a:last-child {
    margin-right: 0;
  }
}

.device-ad-wrap {
  border: 1px solid #f1f1f1;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;

  .device-ad-img {
    float: left;
    width: 200px;
    margin-right: 20px;

    p {
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;
      border: 1px solid #f9f9f9;
    }
  }

  .device-ad-body {
    float: left;
    width: calc(750px - 270px);
    padding-top: 10px;

    h3 {
      margin: 0 0 8px 0;
      font-weight: 600;
    }

    p {
      margin: 5px 0;

      i {
        font-style: normal;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .report-ad {
    color: black;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    span.material-icons {
      margin-right: 3px;
      font-size: 14px;
    }
  }
}

.device-ad-wrap.status-delivered {
  border: 1px solid rgba(21, 87, 36, 0.1);
  background-color: rgba(21, 87, 36, 0.05);
}

.device-ad-wrap.status-created {
  border: 1px solid rgba(24, 144, 255, 0.1);
  background-color: rgba(24, 144, 255, 0.05);
}

#geocoder {
  width: 100%;
  margin-bottom: 20px;

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;

    input {
      display: block;
      width: 100%;
      padding: 14px;
      line-height: 1.5;
      background: #fff;
      border: 1px solid #d7e3e6;
      -webkit-box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      border-radius: 4px;
      font-size: 16px;
    }

    .geocoder-pin-right {
      display: none;
    }
  }
}

.tags-wrap {
  margin-top: 20px;

  .label {
    display: inline-block;
    width: 100%;
    position: relative;
    background: #fff;
    padding: 20px 20px 20px 60px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;

    &:hover {
      cursor: default;
    }

    label:hover,
    input:hover {
      cursor: pointer;
    }

    label {
      font-size: 17px;
      font-weight: 600;
      margin: 0;
    }

    input {
      position: absolute;
      left: 23px;
      top: 23px;
    }
  }
}

.ad-ca {
  position: relative;

  input {
    position: absolute;
    bottom: 15px;
    left: 20px;
  }
}

.sub-industries {
  overflow: hidden;
  max-height: 0;

  &.active {
    max-height: 1000px;
  }
}

.sub-industry {
  padding-left: 30px;
}

.main-industries {
  .label {
    position: relative;

    h4 {
      margin-right: 20px;
    }
  }
}

.dropdown-arrow {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  right: 0;

  img.active {
    transform: rotate(180deg);
  }

  &.active {
    transform: rotate(90deg) translate(70%, 40%);
  }

  &:hover {
    cursor: pointer;
  }
}

.header-with-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-toggle {
  display: none;
}

.hoverable:hover {
  cursor: pointer;
}

.button-toggle-wrapper {
  display: flex;

  .button {
    filter: brightness(0.95);
    width: auto;
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .mobile-only {
    display: block !important;
  }
}

.file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  label {
    cursor: pointer;
    background: #ebebeb;
    color: black;

    &:hover {
      background: #cacaca;
    }
  }
}

.image-edit {
  display: flex;
  align-items: baseline;
  margin: 10px 20px;
  padding: 10px 0;

  .button {
    font-size: 14px;
    white-space: nowrap;

    &:first-of-type {
      margin-right: 10px;
    }
  }
}

.image-text {
  padding: 0 20px;
}

.sub-industry .label {
  background: #f8f8f8;
}

.dropdown-container {
  padding: 20px;
  position: relative;
  margin-right: 10px;
}
</style>
